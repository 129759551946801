import {CustomTextFieldsComponentIds as ComponentIds} from '../../../components/customTextFieldsWidget/config/constants';
import {getRole} from './getRole';
import {helpIds} from '../../../constants/app';
import {WidgetDesignBuilderFn} from '../../editor.app.types';
import {addTextInputField} from './addTextInputField';

export const customTextFieldsDesignBuilderFn: WidgetDesignBuilderFn = (widgetDesignBuilder, t): void => {
  widgetDesignBuilder.set({
    title: t.productPage.customTextWidget.design.panelHeader(),
    customHelpId: helpIds.CUSTOM_TEXT_FIELDS,
  });

  const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
  widgetDesignTabsBuilder
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.customTextWidget.design.title.sectionName(),
        })
        .groups()
        .set({
          roles: [ComponentIds[0].Label, ComponentIds[1].Label].map(getRole),
        });
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: t.productPage.customTextWidget.design.inputField.sectionName(),
        })
        .groups()
        .set({
          roles: [ComponentIds[0].TextBox, ComponentIds[1].TextBox].map(getRole),
        });

      tabBuilder.addSection((sectionBuilder) => addTextInputField(sectionBuilder));
    })
    .addTab((tabBuilder) => {
      tabBuilder
        .set({label: t.productPage.customTextWidget.design.characterCount.sectionName()})
        .groups()
        .set({
          roles: [ComponentIds[0].CharCounter, ComponentIds[1].CharCounter].map(getRole),
        });
    });
};
