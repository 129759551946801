import {ConnectedComponentsBuilder} from '../../editor.app.types';
import {PanelModularizationCustomActionIds} from '../../../constants/gfpp';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {helpIds} from '../../../constants/app';
import {EditorSDK} from '@wix/platform-editor-sdk';
import {getOpenNativePanelWithHelpId} from '../../panels/nativePanel';

export const setGalleryComponentStageBehavior = (
  componentBuilder: ConnectedComponentsBuilder,
  t: ILocaleKeys,
  editorSDK: EditorSDK,
): void => {
  const openGalleryNativePanel = (nativePanelType) =>
    getOpenNativePanelWithHelpId(editorSDK, nativePanelType, helpIds.PRO_GALLERY);

  componentBuilder.set({displayName: t.productPage.galleryWidget.displayName()});
  componentBuilder.gfpp().set('layout', {actionId: PanelModularizationCustomActionIds.GALLERY_LAYOUT});
  componentBuilder
    .gfpp()
    .set('mainAction1', {
      label: t.productPage.galleryWidget.gfpp.layout(),
      actionId: PanelModularizationCustomActionIds.GALLERY_LAYOUT,
    })
    .set('layout', {
      actionId: PanelModularizationCustomActionIds.GALLERY_LAYOUT,
    })
    .set('help', {id: helpIds.PRO_GALLERY})
    .set('settings', {
      onClick: openGalleryNativePanel('settings'),
    });
  componentBuilder.gfpp().set('connect', {behavior: 'HIDE'});
  componentBuilder.gfpp('mobile').set('layout', {actionId: PanelModularizationCustomActionIds.GALLERY_LAYOUT});
  componentBuilder.panel('settings' as never).configureControls({
    'behaviourParams_item_clickAction.LINK': {hidden: true},
    behaviourParams_item_overlay_hoveringBehaviour: {hidden: true},
  } as never);
};
