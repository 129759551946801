import {WidgetBuilder} from '../../editor.app.types';
import {ILocaleKeys} from '../../../locale-keys/LocaleKeys';
import {getRole} from '../global-design/getRole';
import {CustomTextFieldsComponentIds as ComponentIds} from '../../../components/customTextFieldsWidget/config/constants';
import {setComboBoxInputDesignPanelsWithHiddenErrorState} from '../../panels/comboBoxInputPanel';
import {setComponentsAsUnselectable} from './helpers/setComponentsAsUnselectable';

export const setCustomTextFieldsInnerWidgetsStageBehavior = (widgetBuilder: WidgetBuilder, t: ILocaleKeys): void => {
  widgetBuilder
    .configureConnectedComponents(getRole(ComponentIds[0].Label), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.customTextWidget.title.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds[1].Label), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.customTextWidget.title.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds[0].TextBox), (innerWidgetBuilder) => {
      setComboBoxInputDesignPanelsWithHiddenErrorState(innerWidgetBuilder);
      innerWidgetBuilder.set({displayName: t.productPage.customTextWidget.inputField.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds[1].TextBox), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.customTextWidget.inputField.displayName()});
      setComboBoxInputDesignPanelsWithHiddenErrorState(innerWidgetBuilder);
    })
    .configureConnectedComponents(getRole(ComponentIds[0].CharCounter), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.customTextWidget.characterCounter.displayName()});
    })
    .configureConnectedComponents(getRole(ComponentIds[1].CharCounter), (innerWidgetBuilder) => {
      innerWidgetBuilder.set({displayName: t.productPage.customTextWidget.characterCounter.displayName()});
    });
  setComponentsAsUnselectable(ComponentIds[0], widgetBuilder);
  setComponentsAsUnselectable(ComponentIds[1], widgetBuilder);
};
